@import '../../styles/variables';

.cl-logo {
  color: $color-white--24;

  & > a {
    color: currentColor;
  }
}

.cl-logo__image {
  display: block;
  fill: currentColor;
  width: 100%;
  height: 15px;
}
