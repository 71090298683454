@import './../../styles/_variables';
@import './../../styles/shared';

@mixin cart-wrapper-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.cart__wrapper {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  @include cart-wrapper-padding;
}

.cart {
  flex-grow: 1;
  background-color: $color-white;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.cart__inner {
  margin: 40px auto 20px;

  @include respond-to(tablet) {
    padding: 0;
    overflow-x: hidden;
  }
}

.cart__report .cart__report-container {
  @include respond-to(tablet) {
    @include cart-wrapper-padding;
  }
}

@include respond-to(tablet-small) {
  .cart__link-back {
    span {
      display: none;
    }
  }
}

.cart__link-back {
  font-size: $font-size-m;
  line-height: 40px;
  color: $color-gray-dark;
  display: flex;
}

.cart__top-bar {
  margin-top: 40px;
  background: $color-white--80;
  backdrop-filter: blur(15px);

  @include respond-to(tablet) {
    z-index: $z-index--overlay;
    margin-top: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0 0 16px $color-black--08;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.cart__top-bar__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(tablet) {
    padding: 0 16px;
  }
}

.cart__image-container {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 40px;
}

.cart__image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  img {
    position: absolute;
  }
}

.cart__cl-logo {
  position: absolute;
  right: 0;
  align-self: center;
  transform-origin: right;
  transform: rotate(-90deg) translate(50%, -100%);
  margin-bottom: 8px;

  @include respond-to(tablet) {
    .cl-logo__image {
      height: 12px;
    }
  }
}

.cart__nobilia-render-loader {
  position: absolute;
  top: 16px;
  left: 16px;
}

.cart__nobilia-logo {
  position: absolute;
  bottom: 16px;
  left: 16px;
  min-width: 120px;
  width: 30vw;
}

.cart__image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: opacity;
  transition: opacity 300ms $easing--standard;
}

.cart__image--invisible {
  opacity: 0;
}

.cart__mounting-service {
  margin-bottom: 80px;
}

.cart__total-header {
  font-size: $font-size-xl;
  line-height: 21px;
  padding-bottom: 16px;

  @include no-prices {
    display: none;
  }
}

.cart__total-bar {
  z-index: $z-index--overlay;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -12px 24px $color-black--08;
  transition-property: box-shadow, background-color;
  transition-duration: 0.3s;
  @include respond-to(tablet) {
    position: sticky;
    bottom: 0;
  }
}

@page {
  size: 21cm 29.7cm;
  margin: 20mm;
}

@media print {
  .layout {
    display: block !important;
    height: auto !important;
  }

  .main {
    display: block !important;
    overflow: unset !important;
  }

  .cart__inner {
    padding-top: 0;
  }

  .cart__link-back,
  .cart__top-bar,
  .cart__mounting-service--print-invisible {
    display: none;
  }

  .cart__total-header {
    border-top: 1px solid $color-gray-dark--48;
    padding-top: 24px;
  }

  .cart__total-bar {
    position: static;
    box-shadow: none;
  }

  .cart__image-wrapper {
    .cart-total-bar {
      display: none;
    }
  }
}

.cart__dialog-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-black--16;
  z-index: $z-index--dialog;
}

.cart__dialog {
  margin: 8px;
  width: 280px;
}

.cart_dialog-controls {
  justify-content: unset;
}

.cart__dialog-button + .cart__dialog-button {
  margin-left: 16px;
}
