@import '../../../../styles/variables';
@import '../../../../styles/shared';

.locale_switch__wrapper {
}

.locale_switch__popup {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  border-radius: 20px;
  overflow: hidden;
  opacity: 0;
  width: 232px;
  background: $color-white;
  visibility: hidden;
  padding: 24px;

  &:focus {
    outline: none;
  }
}

.locale_switch__popup--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}
