@import './../../styles/variables';
@import './../../styles/shared';

.ar-code {
  background-color: $color-gray-lighter;
  color: $color-gray-dark--48;
  padding: 24px;
  text-align: center;
  font-size: $font-size-s;
  line-height: 21px;
}

.ar-code__description {
  margin-bottom: 8px;
}

.ar-code__qr-wrapper {
  width: 208px;
  height: 208px;
  background: $color-gray-light;
}
.ar-code__qr {
  animation: appear 250ms $easing--standard forwards;
}

.ar-code__app--gplay {
  margin-top: 8px;
}
