@import '../../../../styles/variables';
@import '../../../../styles/shared';

.share-target {
  background-color: $color-gray-lighter;
  color: $color-gray-dark--48;
  padding: 24px;
  text-align: center;
  font-size: $font-size-s;
  line-height: 21px;
}

.share-target--only-copy {
  background-color: transparent;
  padding-top: 0;
}

.share-target__description {
  margin-bottom: 16px;
}

.share-target__variants {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  grid-auto-rows: 40px;
  grid-gap: 16px;
}
