@import "../../styles/variables";
@import "../../styles/shared";

.render-view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render-view__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-white;
  font-size: $font-size-l;
  line-height: 21px;
}

.render-view__art {
  margin: 24px 0;
}

.render-view__progress-bar {
  width: 152px;
  margin-bottom: 40px;
}

.render-view__image-container {
  padding: 24px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
          "img close"
          "img actions"
          "img .";
  grid-template-columns: auto 40px;
  grid-template-rows: 40px auto 40px;

  @include respond-to(tablet-small) {
    grid-template-areas:
            ". close"
            "img img"
            "actions actions";
    grid-template-rows: 40px auto 40px;
  }
}

.render-view__close {
  grid-area: close;
}

.render-view__image-wrapper {
  grid-area: img;
  margin: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: image-grow 300ms $easing--decelerated forwards;

  @include respond-to(tablet-small) {
    margin: 0;
  }
}

@keyframes image-grow {
  0% {
    opacity: 0;
    transform: scale(1.2);
    clip-path: inset(100%);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    clip-path: inset(0);
  }
}

.render-view__image {
  border-radius: 8px;
  box-shadow: 0 8px 16px $color-black--16;
  max-width: 100%;
  height: auto;
}

.render-view__actions {
  grid-area: actions;
  align-self: center;
  display: grid;
  grid-gap: 8px;

  @include respond-to(tablet-small) {
    grid-auto-flow: column;
    justify-content: center;
  }
}

.render-view__action--center {
  align-self: center;
}

.render-view__actions .render-view__copy-tooltip {
  @include respond-from(tablet-small) {
    bottom: auto;
    margin-right: 8px;
    right: 100%;
  }
}

.render-view__actions .render-view__copy-tooltip--visible {
  @include respond-from(tablet-small) {
    animation: slide-left 250ms $easing--standard forwards;
  }
}
