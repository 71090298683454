@import 'reset';
@import 'variables';

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Light.woff') format('woff'),
  url('assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Regular.woff') format('woff'),
  url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/Lato-Bold.woff') format('woff'),
  url('assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  color: $color-gray-dark;
  background-color: $color-white;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

button {
  outline: none;
  padding: 0;
  border: none;
  background: none;

  &:not([disabled]) {
    cursor: pointer;
  }
}

a {
  color: $color-gray-dark;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input {
  &::placeholder {
    color: rgba(46, 56, 77, 0.24);
  }

  &:hover {
    border-color: #8798ad;
  }

  &:focus {
    border-color: #69707f;
  }
}

:link {
  text-decoration: none;
}

