@import './_variables';
@mixin card {
  position: relative;
  padding: 20px 105px 20px 24px;
  color: $color-gray-dark;
  background-color: $color-white;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}
@mixin card__heading {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: $font-size-m;
}

@mixin card__description {
  font-size: $font-size-s;
  line-height: 18px;
}
