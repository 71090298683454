@import './../../../styles/_variables';

.toggle {
  position: relative;
}

.toggle__input {
  position: absolute;
  opacity: 0;
}

.toggle__label {
  position: relative;
  display: block;
  width: 56px;
  height: 32px;
  border-radius: 28px;
  background-color: $color-primary;
  transition-property: background-color, box-shadow;
  transition-duration: 0.3s;
  cursor: pointer;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    display: block;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    box-shadow: 0px 4px 8px $color-black--16;
    border-radius: 50%;
    background-color: $color-white;
    transition-property: left;
    transition-duration: 0.3s;
  }
}

.toggle__input:checked + .toggle__label {
  background-color: $color-additional;

  &::after {
    left: 50%;
  }
}

.toggle__input:focus + .toggle__label {
  box-shadow: 0 0 1px 3px $color-gray-light;
}

.toggle--gray {
  .toggle__label {
    background-color: $color-gray-light;
  }
}
