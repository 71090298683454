@import './../../styles/_variables';

.checkbox__input {
  display: none;
}

.checkbox {
  display: flex;
  padding: 8px 0;

  &__text{
    line-height: 24px;
    font-size: $font-size-xs;
    padding-left: 8px;
  }
}

.checkbox__label {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid $color-white;
  background-color: transparent;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.3s;
}

.checkbox__label-icon {
  position: absolute;
  top: -2px;
  left: -2px;
  opacity: 0;
  transition-property: opacity, fill;
  transition-duration: 0.3s;
  fill: $color-white;
}

.checkbox__label:hover .checkbox__label-icon {
  opacity: 0.48;
}

.checkbox__input:checked + .checkbox__label {
  background-color: $color-white;
}

.checkbox__input:checked + .checkbox__label .checkbox__label-icon {
  opacity: 1;
  fill: $color-additional;
}
