@import './../../styles/_variables';

.filter-width {
  display: flex;
  justify-content: space-between;
  padding: 6px 8px 6px 16px;
  font-size: $font-size-s;
  background: $color-primary;
  box-shadow: 0 4px 8px $color-black--16;
  border-radius: 12px;
  color: $color-white;

  &__label {
    height: 40px;
    line-height: 40px;
    margin: 10px 20px 0 0;
  }

  &__measure {
    margin-top: -7px;
  }

  &__pill-list {
    flex: 0 1 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $color-white;
    justify-content: end;

    .pill {
      color: $color-white;
      border-color: $color-white;
      border-radius: 12px;
      width: 40px;
      height: 40px;
      padding: 0;
    }

    .pill--active {
      background-color: $color-white;
      color: $color-gray-dark--80;
    }
  }
}
