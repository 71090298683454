@import "../../styles/variables";

$render-art--width: 80px;

.render-art {
  width: $render-art--width;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.render-art__stand {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  animation: render-reveal 1s $easing--standard alternate infinite;
}

.render-art__square {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  animation: render-slide 1s $easing--standard alternate infinite;
}

.render-art__line {
  animation: render-line 1s $easing--standard alternate infinite;
}

@keyframes render-reveal {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0% 0 0);
  }
}

@keyframes render-slide {
  0% {
    clip-path: inset(0 0 0 0%);
  }
  100% {
    clip-path: inset(0 0 0 100%);
  }
}

@keyframes render-line {
  0% {
    transform: translateX(-1 * $render-art--width / 2);
  }
  100% {
    transform: translateX($render-art--width / 2);
  }
}

.render-art__error {
  display: flex;
  animation: render-error 500ms $easing--decelerated forwards;
}

@keyframes render-error {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
