$mobile: 440px;
$tablet-small: 600px;
$tablet: 1023px;
$laptop: 1366px;
$desktop: 1920px;

%center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px #00000014;
  }
}

@mixin no-prices() {
  .no-prices & {
    @content;
  }
}

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == tablet-small {
    @media only screen and (max-width: $tablet-small) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (max-width: $tablet) {
      @content;
    }
  } @else if $media == laptop {
    @media only screen and (max-width: $laptop) {
      @content;
    }
  }
}

@mixin respond-from($media) {
  @if $media == mobile {
    @media only screen and (min-width: $mobile) {
      @content;
    }
  } @else if $media == tablet-small {
    @media only screen and (min-width: $tablet-small) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  } @else if $media == laptop {
    @media only screen and (min-width: $laptop) {
      @content;
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e2e6eb;
  }
}

@mixin alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin textOverflowEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
