@import '../../styles/mixin';
@import '../../styles/scroll';

.settings__heading {
  margin: 16px 16px 8px;

  @include heading;
}

.settings__list {
  padding: 0 16px 16px;

  display: grid;
  grid-gap: 8px;
}
