/* color scheme */

$color-white: rgba(255, 255, 255, 1);
$color-white--16: rgba(255, 255, 255, 0.16);
$color-white--24: rgba(255, 255, 255, 0.24);
$color-white--48: rgba(255, 255, 255, 0.48);
$color-white--80: rgba(255, 255, 255, 0.8);

$color-primary: rgba(255, 31, 66, 1);
$color-primary--80: rgba(255, 31, 66, 0.8);
$color-primary-hover: rgba(223, 36, 66, 1);
$color-primary-hover-light: rgb(255, 175, 187);
$color-primary-active: rgba(192, 41, 67, 1);

$color-additional: rgba(52, 204, 204, 1);
$color-additional--08: rgba(52, 204, 204, 0.8);
$color-additional-desaturated: rgba(111, 165, 165, 1);
$color-additional-dark: rgba(41, 177, 177, 1);

$color-gray-lightest: rgba(245, 245, 245, 1);
$color-gray-lighter: rgba(232, 234, 237, 1);
$color-gray-light: rgba(224, 225, 227, 1);
$color-gray-medium: rgba(185, 186, 188, 1);

$color-gray-gradient--16: rgba(128, 128, 128, 0.16);

$color-gray-dark: rgba(58, 63, 69, 1);
$color-gray-dark--24: rgba(58, 63, 69, 0.24);
$color-gray-dark--48: rgba(58, 63, 69, 0.48);
$color-gray-dark--80: rgba(58, 63, 69, 0.8);
$color-gray-dark--90: rgba(58, 63, 69, 0.9);

$color-gradient-top: rgba(232, 132, 110, 1);
$color-gradient-top--24: rgba(232, 132, 110, 0.24);
$color-gradient-top--64: rgba(232, 132, 110, 0.64);
$color-gradient-bottom: rgba(255, 27, 72, 1);
$color-gradient-bottom--24: rgba(255, 27, 72, 0.24);
$color-gradient-bottom--64: rgba(255, 27, 72, 0.64);

$color-black--08: rgba(0, 0, 0, 0.08);
$color-black--16: rgba(0, 0, 0, 0.16);

$color-focus: deepskyblue;

/* typography scheme */
// todo: replace with mixins

$font-size-xl: 1.5rem; /* 24px */
$font-size-l: 1.125rem; /* 18px */
$font-size-m: 1rem; /* 16px */
$font-size-s: 0.875rem; /* 14px */
$font-size-xs: 0.75rem; /* 12px */


/* z-index hierarchy */

$z-index--overlay: 1;
$z-index--sidebar: 10;
$z-index--sidebar-overlay: 11;
$z-index--dialog: 200;

$shadow-panel: 0 0 16px $color-black--08;

$easing--standard: cubic-bezier(0.4, 0.0, 0.2, 1);
$easing--decelerated: cubic-bezier(0.0, 0.0, 0.2, 1);
