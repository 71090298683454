@import '../../../styles/shared';
@import '../../../styles/scroll';
@import '../../../styles/variables';

.select-element {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-gray-lighter;
  display: flex;
  flex-direction: column;
}

// ensure higher specificity
.sidebar .select-element--placement {
  @include respond-to(tablet) {
    pointer-events: none;
    background-color: transparent;

    > * {
      pointer-events: auto;
    }

    .select-element__container {
      display: none;
    }
  }
}

.select-element__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  background-color: $color-primary;
  position: relative;
  z-index: $z-index--sidebar-overlay;
  box-shadow: $shadow-panel;
  color: $color-white;
  flex-shrink: 0;
}

.select-element__container {
  padding: 16px;
  overflow: auto;

  @extend %scrollbar;
}

.select-element__list {
  display: grid;
  grid-template:
          "f" auto
          "c" auto;
  grid-row-gap: 8px;
  grid-column-gap: 16px;

  @include respond-to(tablet) {
    grid-template:
            "f f" auto
            "c c" auto
            / 1fr 1fr;
  }

  @include respond-to(tablet-small) {
    grid-template:
            "f" auto
            "c" auto;
  }
}

.select-element__filter-panel {
  grid-area: f;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;

  position: sticky;
  top: 0;
  z-index: $z-index--sidebar-overlay;
  padding-bottom: 16px;

  @include respond-to(tablet) {
    position: relative;
  }

  @include respond-to(tablet-small) {
    position: sticky;
  }
}

.select-element__item {
  cursor: pointer;
}
