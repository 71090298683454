@import './../../styles/_variables';
@import './../../styles/shared';

.cart-mounting-service__header {
  margin-bottom: 15px;
  font-size: $font-size-xl;
}

.cart-mounting-service__main {
  display: flex;
  justify-content: space-around;
}

.cart-mounting-service__option {
  position: relative;
  width: 100%;
  margin-right: 10px;
}

.cart-mounting-service__option-input {
  position: absolute;
  opacity: 0;
}

.cart-mounting-service__option-label {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 124px;
  width: 100%;
  padding-left: 24px;
  border: 2px solid $color-white;
  border-radius: 10px;
  transition-property: border-color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: $easing--standard;

  &:hover {
    cursor: pointer;
    border-color: $color-primary-hover-light;
  }

  &:active {
    border-color: $color-primary;
  }
}

.cart-mounting-service__option-input:checked + .cart-mounting-service__option-label {
  border-color: $color-primary;
}

.cart-mounting-service__option-input:focus + .cart-mounting-service__option-label {
  box-shadow: 0 0 0 3px $color-primary-hover-light;
}

.cart-mounting-service__option-title,
.cart-mounting-service__option-price {
  font-weight: 700;
  font-size: $font-size-m;
}

.cart-mounting-service__option-price {
  margin-top: 16px;
}

.cart-mounting-service__option-vendor-code {
  font-size: $font-size-xs;
  line-height: 18px;
  margin-top: 8px;
}

@media print {
  .cart-mounting-service__option--print-invisible {
    display: none;
  }

  .cart-mounting-service__option-label {
    border: none;
    box-shadow: none;
  }
}

@include respond-to(mobile) {
  .cart-mounting-service__main {
    flex-direction: column;
  }

  .cart-mounting-service__header {
    margin-bottom: 0;
    margin-top: 48px;
  }

  .cart-mounting-service__option {
    margin-top: 16px;
  }
}
