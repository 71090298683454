@import './../../styles/_variables';

.value-selector {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-s;

  &__measure {
    margin-top: -7px;
  }

  &__label {
    height: 40px;
    line-height: 40px;
  }

  &__pill-list {
    flex-grow: 1;
    justify-content: end;
  }

  &__pill {
    width: 40px;
    height: 40px;
  }
}
