@import '../../styles/_variables';
@import "../../styles/card";

.basic-settings-card {
  @include card();
  &__heading {
    @include card__heading();
  }
  &__description {
    @include card__description();
  }
  &:focus {
    outline: 1px solid $color-focus;
  }
  cursor: pointer;
  box-shadow: 0 4px 8px $color-black--08;
}
.basic-settings-card__image-wrapper {
  position: absolute;
  right: 24px;
  top: 24px;
  border-radius: 12px;
  border: 1px solid $color-gray-light;
  width: 34px;
  height: 34px;
  overflow: hidden;
  cursor: pointer;
}

.basic-settings-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
