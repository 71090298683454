@import './../../styles/variables';
@import './../../styles/shared';

.ar-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.ar-loader__loading-icon {
  margin-bottom: 16px;
  animation: ar-rotate 1.5s linear infinite;
  fill: $color-gray-dark--48;
}

@keyframes ar-rotate {
  0% {
    transform: rotate(0);
  }

  12% {
    transform: rotate(9.9deg);
  }

  24% {
    transform: rotate(39.6deg);
  }

  36% {
    transform: rotate(88.2deg);
  }

  54% {
    transform: rotate(67.5deg);
  }

  74% {
    transform: rotate(88.2deg);
  }

  82% {
    transform: rotate(84.6deg);
  }

  92% {
    transform: rotate(89.1deg);
  }

  96% {
    transform: rotate(88.2deg);
  }

  100% {
    transform: rotate(90deg);
  }
}
