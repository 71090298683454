@import './../../../styles/_variables';

.small-button {
  position: relative;
  box-shadow: 0 8px 16px $color-black--08;
  border-radius: 50%;
  border: none;
  background-color: $color-white;
  padding: 0;
  font-size: 0;
  width: 40px;
  height: 40px;
  color: $color-gray-dark;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-white;
    transition-property: background-color;
    transition-duration: 0.3s;
  }

  &:hover {
    &::before {
      background-color: $color-gray-lightest;
    }
  }

  &:active {
    box-shadow: none;

    &::before {
      background-color: $color-gray-light;
    }
  }

  .icon {
    position: relative;
    z-index: 1;
  }
}

.small-button--primary {
  background-color: $color-primary;
  color: $color-white;

  &::before {
    background-color: $color-primary;
  }

  &:hover {
    &::before {
      background-color: $color-primary-hover;
    }
  }

  &:active {
    box-shadow: none;

    &::before {
      background-color: $color-primary-active;
    }
  }
}

.small-button--transparent {
  background-color: transparent;
  box-shadow: none;
  color: currentColor;

  &::before {
    background-color: transparent;
  }

  &:hover {
    &::before {
      background-color: transparent;
    }
  }

  &:active {
    &::before {
      background-color: transparent;
    }
  }
}
