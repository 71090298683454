@import '../../styles/shared';
@import '../../styles/variables';

.configurator__layout {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.model-viewer_wrapper {
  flex: 2;
  position: relative;
  height: calc(100% - 1px);
  overflow: hidden;
  display: flex;
}

.model-viewer__cl-logo {
  position: absolute;
  right: 0;
  align-self: center;
  transform-origin: right;
  transform: rotate(-90deg) translate(50%, -100%);
  margin-bottom: 8px;
}

.configurator__sidebar {
  width: 344px;
  height: 100%;
  position: relative;

   @include respond-to(tablet) {
     position: absolute;
     width: auto;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     z-index: $z-index--sidebar;
   }
}

.configurator__actions-container {
  position: absolute;
  top: 16px;
  left: 16px;
}

.configurator__action {
  box-shadow: 0 4px 8px $color-black--16;

  & + & {
    margin-left: 16px;
  }

  @include respond-to(tablet) {
    .icon, svg {
      width: 40px;
      height: 40px;
    }
  }
}

.configurator__action-bar {
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}
