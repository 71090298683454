@import '../../../../styles/variables';
@import '../../../../styles/shared';

.cart-link {
  position: absolute;
  z-index: $z-index--overlay;
  right: 0;
  transform: translate(148px, 0);
  top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  border-radius: 16px;
  padding: 8px;
  background-color: $color-white;
  font-weight: 700;
  transition: transform 250ms $easing--standard;
  outline: none;

  @media (hover: hover) {
    &:hover {
      transform: translate(-8px, 0);
    }
  }

  @media (hover: none) {
    &:focus {
      transform: translate(-8px, 0);
    }
  }

  @include respond-to(tablet) {
    height: 40px;
  }

  @include no-prices {
    background: transparent;
    transform: none;
    padding: 0;
    height: auto;
    right: 24px;
    top: 24px;

    &:hover, &:focus {
      transform: none;
    }

    @include respond-to(tablet) {
      right: 16px;
      top: 16px;
    }
  }
}

.cart-link__icon {
  color: $color-primary;

  @include respond-to(tablet) {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  @include no-prices {
    display: none;
  }
}

.cart-link__total {
  margin-left: 16px;
  margin-right: 32px;

  @include respond-to(tablet) {
    margin-left: 8px;
  }
}

.cart-link__button {
  cursor: pointer;
  width: 138px;

  @include respond-to(tablet) {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 16px;
    width: 112px;
  }

  @include no-prices {
    width: unset;
    padding: 8px 8px 8px 16px;
  }
}

.cart-link__button-icon {
  display: none;

  margin-left: 8px;

  @include no-prices {
    display: inline-block;
  }
}
