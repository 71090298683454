@import "../../styles/scroll";
@import '../../styles/mixin';

.elements__list {
  position: relative;
  padding: 0 16px 16px;

  display: grid;
  grid-gap: 8px;

  &.selected {
    height: calc(100% - 60px);
    position: relative;
  }

  scroll-behavior: smooth;
}

.elements__item {
  cursor: pointer;
}

.elements__button-add {
  position: fixed;
  left: 54px;
  bottom: 16px;

  &.button {
    padding: 17px 28px 18px;
  }
}

.elements__heading {
  margin: 16px 16px 8px 16px;

  @include heading;
}
