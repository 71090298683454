@import './../../styles/variables';
@import './../../styles/shared';

.button-with-tooltip__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.button-with-tooltip__tooltip {
  align-self: center;
  position: absolute;
  bottom: 100%;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
}

.button-with-tooltip__tooltip--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}
