@import './../../styles/_variables';

.dialog {
  padding: 24px;
  color: $color-white;

  background: $color-additional--08 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.dialog__content {
  font-size: $font-size-s;
  line-height: 18px;
}

.dialog__controls {
  margin-top: 8px;
  display: flex;
}
