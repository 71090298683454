@import './../../styles/_variables';
@import './../../styles/shared';

.cart-table {
  margin-bottom: 80px;
  @include respond-to(tablet) {
    margin-bottom: 0;
  }
}

.cart-table__header {
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.cart-table__thCell {
  flex-shrink: 0;
  flex-basis: auto;
  font-size: $font-size-s;
  color: $color-gray-dark--48;

  &_title {
    width: calc(100% - 100px - 95px - 80px);
    font-size: $font-size-xl;
    line-height: 21px;
    color: $color-gray-dark;

    @include respond-to(tablet-small) {
      width: 100%;
    }
  }

  &_quantity {
    width: 100px;
    max-width: 100px;
  }

  &_price {
    flex-grow: 95;
    width: 95px;
    max-width: 95px;
  }

  &_subtotal {
    flex-grow: 80;
    width: 80px;
    max-width: 80px;
  }

  @include respond-to(tablet-small) {
    &_quantity, &_price, &_subtotal {
      display: none;
    }
  }
}

.cart-table__footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-gray-dark--48;
  padding-top: 24px;
  page-break-inside: avoid;
}

.cart-table__footer-total {
  font-size: $font-size-m;
  line-height: 21px;
  margin-right: 20px;
}

.cart-table__footer-price {
  font-size: $font-size-xl;
  line-height: 21px;
}

.cart-table__footer-price {
  flex-shrink: 0;
  width: 148px;
  max-width: 148px;
}

.cart-table__footer-vat {
  flex-shrink: 0;
  font-size: $font-size-xs;
  line-height: 21px;
  padding-top: 4px;
  color: $color-gray-dark--48;
}

@media print {
  .cart-table__thCell_title {
    width: calc(100% - 70px - 95px - 80px);

    @include no-prices {
      width: calc(100% - 100px);
    }
  }

  .cart-table__thCell_quantity {
    width: 70px;
    max-width: 70px;
  }
}
