@import './../../styles/_variables';

.pill {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  border-radius: 4px;
  border: 1px solid $color-gray-dark;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.3s;
  background: none;

  &--active {
    background-color: $color-gray-dark;
    color: $color-white;
    box-shadow: 0 4px 8px $color-black--16;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}
