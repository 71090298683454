@import "../../../styles/mixin";
@import "../../../styles/shared";
@import "../../../styles/variables";

.edit-worktop {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-gray-lighter;
  display: flex;
  flex-direction: column;
}

.edit-worktop__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  background-color: $color-white;
  position: relative;
  z-index: $z-index--sidebar-overlay;
  box-shadow: $shadow-panel;
  color: $color-gray-dark;
}

.edit-worktop__options {
  padding: 24px;
  background-color: $color-white;
}

.edit-worktop__container {
  padding: 16px;
  overflow: auto;

  @extend %scrollbar;
}

.edit-worktop__heading {
  @include heading;

  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 300;
}

.edit-worktop__list {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;

  @include respond-to(tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to(tablet-small) {
    grid-template-columns: 1fr;
  }
}

.edit-worktop__panel--bottom {
  @include bottom-panel;

  @include respond-to(tablet) {
    display: none;
  }
}

.edit-worktop__add-button {
  @include bottom-panel__button;
}
