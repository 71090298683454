@import '../../../../styles/variables';
@import '../../../../styles/shared';

.ar-button__wrapper {
}

.ar-button__popup {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  border-radius: 20px;
  overflow: hidden;
  opacity: 0;
  width: 256px;
  visibility: hidden;

  &:focus {
    outline: none;
  }
}

.ar-button__popup--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}
