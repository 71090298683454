@import './../../styles/_variables';
@import './../../styles/shared';

// todo: rename classes to lessen confusion with ReportList

.cart-table__card {
  display: grid;
  grid-template-columns: calc(100% - 100px - 95px - 80px) 100px 95px 80px;
  grid-template-rows: 32px auto;
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 8px 0;
  background-color: $color-white;
}

.cart-table__tdCell {
  font-size: $font-size-s;

  &_info {
    grid-row: 1 / span 2;
    min-height: 124px;
    position: relative;
    padding-left: 156px;
    padding-right: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    font-weight: 400;

    &::after {
      content: '';
      position: absolute;
      right: 8px;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background-color: $color-gray-light;
    }
  }

  &_quantity {
    align-self: center;
    padding-left: 20px;
  }

  &_price {
    align-self: center;
    font-weight: 700;
  }

  &_subtotal {
    align-self: center;
    font-weight: 700;
  }
}

.cart-table__card_excluded .cart-table__tdCell_info > *,
.cart-table__card_excluded .cart-table__tdCell_quantity,
.cart-table__card_excluded .cart-table__tdCell_price,
.cart-table__card_excluded .cart-table__tdCell_subtotal {
  opacity: 0.24;
}

.cart-table__img-wrapper {
  position: absolute;
  left: 8px;
  top: 0;
  max-width: 124px;
  height: 100%;
}

.cart-table__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cart-table__title {
  flex-basis: 100%;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: $font-size-m;
}

.cart-table__data {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 170px));
  grid-gap: 4px 20px;

  @include respond-to(tablet-small) {
    grid-gap: 8px;
  }
}

.cart-table__link {
  margin-bottom: 8px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-xs;
  line-height: 18px;
  color: $color-additional-dark;
}

.cart-table__vendor-code {
  font-size: $font-size-xs;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.cart-table__description {
  margin-top: 12px;
  flex-basis: 100%;
  font-size: $font-size-s;
  line-height: 18px;
}

.cart-table__toggle-wrapper {
  grid-column: 2 / span 3;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;

  @include no-prices {
    grid-column: 3 / span 2;
    margin-top: 0;
  }
}

.cart-table__toggle-text {
  flex-grow: 1;
  margin-left: 24px;
  font-size: $font-size-s;
  font-weight: 400;
  color: $color-gray-medium;

  @include no-prices {
    order: -1;
    margin-left: 0;
    margin-right: 24px;
    text-align: right;
  }
}
.cart-table__subheader {
  font-weight: 400;
  color: $color-gray-medium;
}

.input__input {
  border-radius: 4px;
  border: 1px solid $color-gray-dark;
  background-color: $color-white;
  padding: 8px;
  width: 56px;
  height: 32px;
  margin-left: -20px;

  &:focus {
    outline: none;
  }
}

.cart-table__subheader {
  display: none;
}

@include respond-to(tablet-small) {
  .cart-table {
    margin-bottom: 48px;
  }
  .cart__image-wrapper .cart-total-bar {
    display: none;
  }

  .cart-table__card {
    padding: 16px;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 4px 8px $color-black--08;
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(3, 1fr);

    @include no-prices {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .cart-table__img-wrapper {
    display: none;
  }

  .cart-table__tdCell {
    &_info {
      justify-content: space-between;
      grid-row: 1;
      grid-column: 1 / span 4;
      border: none;
      min-height: auto;
      padding: 0 0 16px;
      border-bottom: 1px solid #eee;
      margin-bottom: 16px;

      &::after {
        content: none;
      }
    }

    &_quantity, &_price, &_subtotal {
      grid-row: 2;
      padding-right: 16px;
      display: flex;
      flex-direction: column;
      align-self: stretch;
    }
    &_price {
      grid-column: 2;
    }
    &_subtotal {
      grid-column: 3;
    }
    &_quantity {
      padding-left: 0;

      .input__input {
        margin: auto 0 0;
      }
    }
  }

  .cart-table__toggle-wrapper {
    grid-column: 1 / span 3;
    grid-row: 3;
    justify-content: flex-start;

    .cart-table__toggle-text {
      flex-grow: 0;
    }

    @include no-prices {
      grid-column: 2 / span 3;
      grid-row: 2;
      align-self: flex-end;
      justify-content: flex-end;
    }
  }

  .cart-table__subheader {
    display: flex;
    padding-bottom: 16px;
  }

  .cart-table__tdCell__value {
    line-height: 32px;
    margin-top: auto;
  }
}

@media print {
  .cart-table__card {
    page-break-inside: avoid;

    @include no-prices {
      grid-template-columns: calc(100% - 100px) 100px;
    }
  }

  .cart-table__tdCell_info {
    padding-left: 175px;
  }

  .cart-table__thCell_quantity,
  .cart-table__thCell_price,
  .cart-table__thCell_subtotal,
  .cart-table__tdCell_quantity,
  .cart-table__tdCell_price,
  .cart-table__tdCell_subtotal {
    text-align: end;
  }
  
  .cart-table__data {
    grid-template-columns: none;
    grid-gap: 8px
  }

  .input__input {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    border: none;
    padding-left: 0;
    margin-left: initial;
  }

  .cart-table__toggle-wrapper,
  .cart-table__description,
  .cart-table__card_excluded,
  .cart-table__card--print-invisible {
    display: none;
  }
}
