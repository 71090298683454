@import './../../styles/_variables';

.dimensions {
}

.dimensions__item {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
  font-size: $font-size-xs;
  line-height: 18px;

  &::after {
    content: '';
    margin-left: 6px;
    display: block;
    width: 1px;
    height: $font-size-xs;
    background-color: currentColor;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      content: none;
    }
  }
}
