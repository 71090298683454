@import './_variables';

@mixin heading {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: $color-gray-dark;
}

@mixin bottom-panel {
  position: absolute;
  margin-bottom: 16px;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  pointer-events: none;
  z-index: $z-index--sidebar-overlay;
  justify-content: space-around;
}

@mixin bottom-panel__button {
  // a hacky way to solve an issue with undeterministic CSS order due to mini-css-webpack or webpack itself
  // https://jira5.sam-solutions.net/projects/NBLC/issues/NBLC-217
  &.button {
    padding: 16px 28px;
    pointer-events: auto;
  }
}
