@import './../../styles/shared';

.action-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 16px 24px;

  @include respond-to(tablet) {
    margin: 16px;
    grid-template-columns: 1fr auto auto;
    grid-gap: 16px;
  }
}

.action-bar__actions {
  & > * + * {
    margin-left: 16px;
  }
}

.action-bar__actions--left {
  justify-self: self-start;
  display: flex;
}

.action-bar__actions--center {
  justify-self: center;

  @include respond-to(tablet) {
    justify-self: end;
  }
}

.action-bar__actions--right {
  justify-self: self-end;
  display: flex;
}

.action-bar__action--vertical-center {
  align-self: center;
}
