@import "../../styles/variables";

.progress-bar__track {
  height: 8px;
  display: flex;
  border-radius: 27px;
  background: $color-white--24;
}

.progress-bar__progress {
  border-radius: 27px;
  background: $color-additional;
  transition: width 150ms $easing--standard;
}