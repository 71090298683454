@import './../../../styles/_variables';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  border: none;
  border-radius: 10px;
  padding: 10px 16px 11px;
  background-color: $color-primary;
  color: $color-white;
  font-weight: 700;
  font-size: $font-size-m;
  transition-property: background-color, box-shadow;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $color-primary-hover;
  }

  &:active {
    background-color: $color-primary-active;
  }

  &_white {
    box-shadow: 0 8px 16px $color-black--08;
    background-color: $color-white;
    color: $color-gray-dark;

    &:hover {
      box-shadow: none;
      background-color: $color-gray-lightest;
    }

    &:active {
      box-shadow: none;
      background-color: $color-gray-light;
    }
  }

  &_gray {
    background-color: $color-gray-lightest;
    color: $color-gray-dark;
    font-weight: 500;

    &:hover {
      background-color: $color-gray-light;
    }

    &:active {
      background-color: $color-gray-medium;
    }
  }

  &_with-icon {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 24px; /* same height as icon */
  }

  &_transparent {
    box-shadow: none;
    background: transparent;
    color: $color-gray-dark;

    &:hover {
      background-color: $color-gray-lightest;
    }

    &:active {
      background-color: $color-gray-light;
    }
  }

  &_full-width {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.24;
    cursor: default;
  }

  .icon + * {
    margin-left: 8px;
  }
}
