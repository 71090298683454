@import './../../styles/_variables';
@import './../../styles/shared';

.selector-handle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $font-size-s;

  &__icons {
    display: flex;
    margin-left: auto;
  }
}

.selector-handle__label {
  display: inline-flex;
  text-align: center;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $color-gray-dark;
  line-height: 11px;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  color: $color-gray-dark;
  cursor: pointer;
  padding-right: 8px;

  & + & {
    margin-left: 8px;
  }

  @include respond-to(mobile) {
    padding-right: 0;

    span {
      display: none;
    }
  }
}

.selector-handle__label.checked {
  background-color: $color-gray-dark;
  color: $color-white;
}
