@import '../../../styles/shared';
@import '../../../styles/variables';
@import '../../../styles/mixin';
@import '../../../styles/scroll';

.select-category {
  background-color: $color-primary;
  color: $color-white;
  height: 100%;
}

.select-category__panel {
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 16px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  &__button {
    display: none;

    & + & {
      margin-left: 16px;
    }
  
    @include respond-to(tablet) {
      display: block;
    }
  }
}

.select-category__container {
  display: flex;
  max-height: 100%;
  overflow: hidden;
}

.select-category__column {
  padding: 24px 16px 0;
  flex: 1;

  @include v-scroll;
}

.select-category__heading {
  padding: 0 40px 0 24px;
  @include heading;
  color: $color-white;
}

.select-category__description {
  margin-bottom: 40px;
  padding: 0 40px 0 24px;
  margin-top: 21px;
  font-size: $font-size-s;
  line-height: 21px;
}

.select-category__list {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;

  @include respond-to(tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to(tablet-small) {
    grid-template-columns: 1fr;
  }
}

.select-category__item {
  cursor: pointer;
}
