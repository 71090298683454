@import './../../../styles/_variables';
@import './../../../styles/shared';

.button-round {
  position: relative;
  border: none;
  border-radius: 50%;
  padding: 0;
  background-color: transparent;
  width: 54px;
  height: 54px;
  cursor: pointer;
  color: $color-gray-dark;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include respond-to(tablet) {
    width: 40px;
    height: 40px;

    & .icon {
      width: 24px;
      height: 24px;

      & svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    transition-property: background-color, opacity;
    transition-duration: 0.3s;
    z-index: $z-index--overlay;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(225deg, $color-gradient-top--64 0%, $color-gradient-bottom--64 100%);
    backdrop-filter: blur(30px);
  }

  &:hover {
    &::before {
      background-color: $color-gray-lightest;
    }
  }

  &:active, &--active{
    color: $color-white;

    &::before {
      opacity: 0;
    }

    .icon__secondary {
      fill: currentColor;
    }
  }
}

.button-round__icon {
  position: relative;
  z-index: $z-index--overlay;
  width: 100%;
}

.button-round--primary {
  background-color: $color-primary;
  color: $color-white;
  fill: $color-white;

  &::before {
    background-color: $color-primary;
  }

  &:hover {
    &::before {
      background-color: $color-primary-hover;
    }
  }

  &:active {
    box-shadow: none;

    &::before {
      background-color: $color-primary-active;
    }
  }
}
.button-round--small {
  width: 40px;
  height: 40px;
}
