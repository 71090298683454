@import '../../../../styles/variables';
@import '../../../../styles/shared';

.action-menu__wrapper {
}

.action-menu__popup {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  border-radius: 12px;
  opacity: 0;
  width: 256px;
  background: $color-white;
  visibility: hidden;

  /**
   * todo: introduce better max-height calculation
   */
  max-height: calc(100vh - 40px - 16px - 8px - 16px);
  overflow: auto;
  z-index: $z-index--dialog;

  @extend %scrollbar;

  &:focus {
    outline: none;
  }
}

.action-menu__popup--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}

.action-menu__tooltip {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
}

.action-menu__tooltip--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}

.action-menu__main {
  padding: 24px 24px 8px;
  will-change: padding;
}

.action-menu__header {
  font-size: $font-size-l;
  line-height: 21px;
  margin-bottom: 16px;
}

.action-menu__toggle__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-menu__subheader,
.action-menu__toggle__label {
  font-size: $font-size-s;
  line-height: 21px;
  font-weight: bold;
}

.action-menu__subheader {
  margin-bottom: 16px;
}

.action-menu__separator {
  margin-top: 16px;
  margin-bottom: 24px;
  border: none;
  border-top: 1px $color-gray-light solid;
}

.action-menu__actions,
.action-menu__lang {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.action-menu__lang {
  margin-bottom: 24px;
}

.action-menu__action-content {
  display: flex;
  transform-origin: bottom;
  max-height: 16px;
  min-height: 16px;

  &--visible {
    max-height: 400px;
  }

  & > * {
    flex-grow: 1;
  }
}

.ar-link {
  padding: 24px;
  background-color: $color-gray-lighter;
  color: $color-gray-dark--48;
  text-align: center;
  font-size: $font-size-s;
  line-height: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ar-link__content {
  margin-bottom: 16px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ar-link__link {
  font-weight: bold;
  color: $color-additional-desaturated;
}
