@import './../../styles/_variables';
@import './../../styles/shared';

.elements-card {
  display: grid;
  grid-template-areas: "heading heading" "img data" "img description";
  grid-template-columns: 124px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  grid-template-rows: auto auto 1fr;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: $color-white;
  color: $color-gray-dark;
  min-height: 165px;
  border: 2px solid transparent;
  box-shadow: 0 4px 8px $color-black--08;

  cursor: pointer;
  position: relative;
  transition-property: border-color, border-width;
  transition-delay: 0.3s;

  &:focus {
    outline: 1px solid $color-focus;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &_installed {
/*    border-color: $color-additional;*/
  }

  &_selected {
    border-width: 2px;
    border-color: $color-primary;
  }

  &_max-quantity {
/*    border-color: $color-gray-dark;*/
    color: $color-gray-dark--24;
  }
}

.elements-card-in-details .elements-card {
  box-shadow: none;
}

.elements-card__status {
  position: absolute;
  left: 4px;
  bottom: 4px;
  border-radius: 12px;
  padding: 4.5px 8px;
  font-weight: 700;
  font-size: $font-size-xs;
  color: $color-white;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  transition-property: opacity, visibility;
  transition-delay: 0.3s;
  z-index: 1;
}

.elements-card_installed .elements-card__status {
  background-color: $color-additional;
  opacity: 1;
  visibility: visible;
}

.elements-card_max-quantity .elements-card__status {
  background-color: $color-gray-dark;
  opacity: 1;
  visibility: visible;
}

.elements-card__action-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  background-color: $color-primary--80;
  backdrop-filter: blur(2px);
  font-weight: 700;
  font-size: $font-size-s;
  line-height: 16px;
  color: $color-white;
}

.elements-card__image-wrapper {
  grid-area: img;
  max-width: 124px;
  min-height: 124px;
  max-height: 170px;
  padding: 4px;
  display: flex;
  align-self: start;
}

.elements-card_max-quantity .elements-card__image-wrapper {
  opacity: 0.24;
}

.elements-card__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.elements-card__data {
  grid-area: data;
  align-self: center;
  color: $color-gray-medium;
  font-size: $font-size-xs;
  line-height: 18px;
}

.elements-card__heading {
  grid-area: heading;
  font-weight: 700;
  font-size: $font-size-s;
  line-height: 21px;
}

.elements-card__type-number {
  margin-bottom: 8px;
}

.elements-card__price {
  font-weight: 700;
  color: $color-gray-dark;
  margin-bottom: 8px;
}

.elements-card__dimensions {
  margin-bottom: 8px;
}

.elements-card__description {
  font-size: $font-size-xs;
  line-height: 18px;
  margin-top: 4px;
  grid-area: description;

  input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    word-break: break-word;
    overflow: hidden;
  }

  &.expanded {
    p {
      -webkit-line-clamp: 8;
    }

    .elements-card__more-info {
      display: none;
    }

    p.truncated ~ .elements-card__less-info {
      display: block;
    }
  }

  p:not(.truncated) ~ label {
    display: none;
  }

  .elements-card__more-info,
  .elements-card__less-info {
    display: block;
    border: none;
    background-color: transparent;
    padding: 8px;
    margin-left: -8px;
    margin-bottom: -8px;
    font-size: $font-size-xs;
    color: $color-additional-dark;
    cursor: pointer;
  }

  .elements-card__less-info {
    display: none;
  }
}
