@import './../../styles/_variables';

.navigation__item {
}

.navigation__link {
  display: block;
  position: relative;
  padding: 12px 64px;
  color: inherit;
}

.navigation__link-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  fill: $color-white;
}

.navigation__link-title {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: $font-size-l;
  line-height: 24px;
}

.navigation__link-text {
  font-size: $font-size-xs;
  opacity: 0.48;
}

.navigation__link-icon-arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    transform: rotate(180deg)
  }
}
