@import "../../styles/variables";

@mixin f1 {
  top: 0;
  right: 0;
  height: 33.3333%;
}
@mixin f2 {
  top: 0;
  right: 33.3333%;
  height: 33.3333%;
}
@mixin f3 {
  top: 0;
  right: 66.6666%;
  height: 33.3333%;
}
@mixin f4 {
  top: 0;
  right: 66.6666%;
  height: 100%;
}
@mixin f5 {
  top: 66.6666%;
  right: 66.6666%;
  height: 33.3333%;
}
@mixin f6 {
  top: 66.6666%;
  right: 33.3333%;
  height: 33.3333%;
}
@mixin f7 {
  top: 66.6666%;
  right: 0;
  height: 33.3333%;
}
@mixin f8 {
  top: 0;
  right: 0;
  height: 100%;
}
@keyframes fb-1 {
  0% {
    @include f4;
  }
  2.7777777777777777% {
    @include f5;
  }
  5.555555555555555% {
    @include f6;
  }
  8.333333333333332% {
    @include f7;
  }
  11.11111111111111% {
    @include f8;
  }
  13.88888888888889% {
    @include f8;
  }
  16.666666666666664% {
    @include f1;
  }
  19.444444444444443% {
    @include f1;
  }
  22.22222222222222% {
    @include f2;
  }
  25% {
    @include f2;
  }
  27.77777777777778% {
    @include f3;
  }
  30.555555555555554% {
    @include f3;
  }
  33.33333333333333% {
    @include f4;
  }
  36.11111111111111% {
    @include f5;
  }
  38.888888888888886% {
    @include f6;
  }
  41.666666666666664% {
    @include f7;
  }
  44.44444444444444% {
    @include f8;
  }
  47.22222222222222% {
    @include f8;
  }
  50% {
    @include f1;
  }
  52.77777777777778% {
    @include f1;
  }
  55.55555555555556% {
    @include f2;
  }
  58.33333333333333% {
    @include f2;
  }
  61.11111111111111% {
    @include f3;
  }
  63.888888888888886% {
    @include f3;
  }
  66.66666666666666% {
    @include f4;
  }
  69.44444444444444% {
    @include f5;
  }
  72.22222222222221% {
    @include f6;
  }
  75% {
    @include f7;
  }
  77.77777777777777% {
    @include f8;
  }
  80.55555555555556% {
    @include f8;
  }
  83.33333333333333% {
    @include f1;
  }
  86.11111111111111% {
    @include f1;
  }
  88.88888888888889% {
    @include f2;
  }
  91.66666666666666% {
    @include f2;
  }
  94.44444444444444% {
    @include f3;
  }
  97.22222222222221% {
    @include f3;
  }
  100% {
    @include f4;
  }
}
@keyframes fb-2 {
  0% {
    @include f1;
  }
  2.7777777777777777% {
    @include f1;
  }
  5.555555555555555% {
    @include f2;
  }
  8.333333333333332% {
    @include f2;
  }
  11.11111111111111% {
    @include f3;
  }
  13.88888888888889% {
    @include f3;
  }
  16.666666666666664% {
    @include f4;
  }
  19.444444444444443% {
    @include f5;
  }
  22.22222222222222% {
    @include f6;
  }
  25% {
    @include f7;
  }
  27.77777777777778% {
    @include f8;
  }
  30.555555555555554% {
    @include f8;
  }
  33.33333333333333% {
    @include f1;
  }
  36.11111111111111% {
    @include f1;
  }
  38.888888888888886% {
    @include f2;
  }
  41.666666666666664% {
    @include f2;
  }
  44.44444444444444% {
    @include f3;
  }
  47.22222222222222% {
    @include f3;
  }
  50% {
    @include f4;
  }
  52.77777777777778% {
    @include f5;
  }
  55.55555555555556% {
    @include f6;
  }
  58.33333333333333% {
    @include f7;
  }
  61.11111111111111% {
    @include f8;
  }
  63.888888888888886% {
    @include f8;
  }
  66.66666666666666% {
    @include f1;
  }
  69.44444444444444% {
    @include f1;
  }
  72.22222222222221% {
    @include f2;
  }
  75% {
    @include f2;
  }
  77.77777777777777% {
    @include f3;
  }
  80.55555555555556% {
    @include f3;
  }
  83.33333333333333% {
    @include f4;
  }
  86.11111111111111% {
    @include f5;
  }
  88.88888888888889% {
    @include f6;
  }
  91.66666666666666% {
    @include f7;
  }
  94.44444444444444% {
    @include f8;
  }
  97.22222222222221% {
    @include f8;
  }
  100% {
    @include f1;
  }
}
@mixin s1 {
  top: 0;
  right: 33.3333%;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s2 {
  top: 0;
  right: 33.3333%;
  height: 33.3333%;
  width: 66.6666%;
}
@mixin s3 {
  top: 0;
  right: 66.6666%;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s4 {
  top: 0;
  right: 66.6666%;
  height: 100%;
  width: 33.3333%;
}
@mixin s5 {
  top: 66.6666%;
  right: 66.6666%;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s6 {
  top: 66.6666%;
  right: 150%;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s7 {
  top: 66.6666%;
  right: 66.6666%;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s8 {
  top: 66.6666%;
  right: 33.3333%;
  height: 33.3333%;
  width: 33.3333%;
}

@mixin s9 {
  top: 66.6666%;
  right: 33.3333%;
  height: 33.3333%;
  width: 66.6666%;
}
@mixin s10 {
  top: 66.6666%;
  right: 0;
  height: 33.3333%;
  width: 66.6666%;
}
@mixin s11 {
  top: 66.6666%;
  right: 0;
  height: 33.3333%;
  width: 33.3333%;
}
@mixin s12 {
  top: 0;
  right: 0;
  height: 33.3333%;
  width: 33.3333%;
}
@keyframes sb-1 {
  0% {
    @include s6;
  }
  2.7777777777777777% {
    @include s6;
  }
  5.555555555555555% {
    @include s7;
  }
  8.333333333333332% {
    @include s8;
  }
  11.11111111111111% {
    @include s8;
  }
  13.88888888888889% {
    @include s9;
  }
  16.666666666666664% {
    @include s10;
  }
  19.444444444444443% {
    @include s10;
  }
  22.22222222222222% {
    @include s11;
  }
  25% {
    @include s12;
  }
  27.77777777777778% {
    @include s1;
  }
  30.555555555555554% {
    @include s1;
  }
  33.33333333333333% {
    @include s1;
  }
  36.11111111111111% {
    @include s2;
  }
  38.888888888888886% {
    @include s3;
  }
  41.666666666666664% {
    @include s4;
  }
  44.44444444444444% {
    @include s5;
  }
  47.22222222222222% {
    @include s6;
  }
  50% {
    @include s6;
  }
  52.77777777777778% {
    @include s6;
  }
  55.55555555555556% {
    @include s7;
  }
  58.33333333333333% {
    @include s8;
  }
  61.11111111111111% {
    @include s8;
  }
  63.888888888888886% {
    @include s9;
  }
  66.66666666666666% {
    @include s10;
  }
  69.44444444444444% {
    @include s10;
  }
  72.22222222222221% {
    @include s11;
  }
  75% {
    @include s12;
  }
  77.77777777777777% {
    @include s1;
  }
  80.55555555555556% {
    @include s1;
  }
  83.33333333333333% {
    @include s1;
  }
  86.11111111111111% {
    @include s2;
  }
  88.88888888888889% {
    @include s3;
  }
  91.66666666666666% {
    @include s4;
  }
  94.44444444444444% {
    @include s5;
  }
  97.22222222222221% {
    @include s6;
  }
  100% {
    @include s6;
  }
}
@keyframes sb-2 {
  0% {
    @include s1;
  }
  2.7777777777777777% {
    @include s2;
  }
  5.555555555555555% {
    @include s3;
  }
  8.333333333333332% {
    @include s4;
  }
  11.11111111111111% {
    @include s5;
  }
  13.88888888888889% {
    @include s6;
  }
  16.666666666666664% {
    @include s6;
  }
  19.444444444444443% {
    @include s6;
  }
  22.22222222222222% {
    @include s7;
  }
  25% {
    @include s8;
  }
  27.77777777777778% {
    @include s8;
  }
  30.555555555555554% {
    @include s9;
  }
  33.33333333333333% {
    @include s10;
  }
  36.11111111111111% {
    @include s10;
  }
  38.888888888888886% {
    @include s11;
  }
  41.666666666666664% {
    @include s12;
  }
  44.44444444444444% {
    @include s1;
  }
  47.22222222222222% {
    @include s1;
  }
  50% {
    @include s1;
  }
  52.77777777777778% {
    @include s2;
  }
  55.55555555555556% {
    @include s3;
  }
  58.33333333333333% {
    @include s4;
  }
  61.11111111111111% {
    @include s5;
  }
  63.888888888888886% {
    @include s6;
  }
  66.66666666666666% {
    @include s6;
  }
  69.44444444444444% {
    @include s6;
  }
  72.22222222222221% {
    @include s7;
  }
  75% {
    @include s8;
  }
  77.77777777777777% {
    @include s8;
  }
  80.55555555555556% {
    @include s9;
  }
  83.33333333333333% {
    @include s10;
  }
  86.11111111111111% {
    @include s10;
  }
  88.88888888888889% {
    @include s11;
  }
  91.66666666666666% {
    @include s12;
  }
  94.44444444444444% {
    @include s1;
  }
  97.22222222222221% {
    @include s1;
  }
  100% {
    @include s1;
  }
}
$animation-duration: 20s;

.first-block {
  animation: fb-1 $animation-duration infinite;
}
.second-block {
  animation: sb-1 $animation-duration infinite;
}
.third-block {
  animation: fb-2 $animation-duration infinite;
}
.fourth-block {
  animation: sb-2 $animation-duration infinite;
}
.loading-mask-container {
  background: rgba(27,31,35,0.9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loading-mask-wrapper {
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100px;
  height: 100px;
  //width: 20%;
  //padding-top: 18%;
}


.block-wrapper {
  position: absolute;
  width: 33.3333%;
  height: 33.3333%;
  padding: 3%;
}

.block {
  box-sizing: border-box;
  border: solid $color-gray-lightest;
  height: 100%;
  width: 100%;
  border-width: 0.3rem;
  border-radius: 8px;
}
