@import './../../styles/variables';
@import './../../styles/shared';

.share-button__wrapper {
  position: relative;
}

.share-button__tooltip {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
}

.share-button__popup {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  border-radius: 20px;
  overflow: hidden;
  opacity: 0;
  width: 256px;
  visibility: hidden;
}

.share-button__popup--visible,
.share-button__tooltip--visible {
  visibility: visible;
  animation: slide-up 250ms $easing--standard forwards;
}
