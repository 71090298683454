@import './../../styles/variables';
@import './../../styles/mixin';

.related-products__action {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  margin-bottom: 5px;
  margin-top: 15px;
  align-items: baseline;
}

.related-products__select {
  color: $color-additional-desaturated;
  cursor: pointer;
  background: none;
  border: none;
  font-size: $font-size-s;
  padding: 0;

  & + & {
    margin-left: 16px;
  }
}

.related-products__type {
  color: $color-gray-dark;
  flex: 1 0 100%;
  margin-bottom: 12px;
  font-size: $font-size-s;
}

.related-products__heading {
  @include heading;

  margin: 16px 16px 40px 24px;
  font-size: 24px;
  font-weight: 300;
}

.related-products__list {
  margin-bottom: 20px;
  padding: 0 16px;
}

.empty-additional {
  padding-left: 8px;
  color: $color-gray-dark--48;
  font-size: $font-size-s;
}