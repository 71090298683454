@import "../../styles/variables";
.model-viewer, .model-viewer-canvas {
  width: 100%;
  height: 100%
}
.model-viewer {
  position: relative;
}

.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-gray-lightest;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-shim {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-gray-dark--90;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index--dialog;
}

.back-image {
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}

