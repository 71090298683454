@import './../../styles/_variables';
@import './../../styles/shared';

.cart-total-bar {
  color: $color-white;
  background-color: $color-primary--80;
  backdrop-filter: blur(8px);

  @include no-prices() {
    @include respond-to(tablet-small) {
      display: none;
    }
  }
}

.cart-total-bar__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  min-height: 72px;
}

.cart-total-bar__list {
  flex-grow: 200;
  display: flex;

  @include no-prices() {
    display: grid;
    grid-template-columns: repeat(3, 194px);
    justify-content: center;
    text-align: center;
  }

  @include respond-to(tablet-small) {
    display: none;
  }
}

.cart-total-bar__item {
  position: relative;
  margin-right: 16px;
  font-size: $font-size-xl;
  line-height: 24px;

  a {
    color: $color-white;
  }

  &::after {
    content: '';
    position: absolute;
    right: -9px;
    top: 0;
    margin-left: 8px;
    display: block;
    width: 2px;
    height: $font-size-xl;
    background-color: currentColor;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      content: none;
    }
  }
}

.cart-total-bar__total {
  flex-grow: 150;
  width: 150px;
  max-width: 150px;
}

.cart-total-bar__total-price {
  margin-bottom: 4px;
  font-size: $font-size-xl;
}

.cart-total-bar__total-vat {
  font-size: $font-size-xs;
  color: $color-white--48;
}

.cart-total-bar__button-checkout {
  flex-grow: 110;
  width: 110px;
  max-width: 110px;
  justify-content: flex-end;
  display: flex;

  @include no-prices {
    margin-left: auto;
  }
}

.cart-total-bar__button-down {
  position: absolute;
  right: calc(-45px - 40px); // 40px = icon width
  top: 50%;
  transform: translateY(-50%);
  transition-property: opacity;
  transition-duration: 0.3s;
}

.cart-total-bar_transparent .cart-total-bar__button-down {
  opacity: 0;
}

@media print {
  .cart-total-bar {
    background: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    color: $color-gray-dark;

    .cart-total-bar__item {
      font-size: $font-size-l;
    }

    .cart-total-bar__wrapper {
      padding-top: 0;
      margin: 0;
      align-items: flex-start;
    }

    .cart-total-bar__button-checkout {
      display: none;
    }

    * {
      color: $color-gray-dark !important;
    }

    @include no-prices() {
      display: none;
    }
  }
}
