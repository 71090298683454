@import '../../../styles/shared';
@import '../../../styles/scroll';
@import '../../../styles/variables';
@import '../../../styles/mixin';

.overview {
  background-color: $color-gray-lighter;
  height: 100%;
}

.overview__panel {
  @include bottom-panel;

  padding: 16px 16px 0 16px;
  pointer-events: none;

  @include respond-to(tablet) {
    top: 0;
    bottom: unset;
    justify-content: flex-end;
  }

  &__button {
    display: none;
    pointer-events: auto;

    &--persistent {
      display: flex;
    }

    & + & {
      margin-left: 16px;
    }

    @include respond-to(tablet) {
      display: flex;
    }
  }
}

.overview__container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;

  @extend %scrollbar;

  &::-webkit-scrollbar-track {
    margin-top: 64px;
    margin-bottom: 102px;
  }

  @include respond-to(tablet) {
    flex-direction: row;

    &::-webkit-scrollbar-track {
      margin-bottom: 0;
    }
  }

  @include respond-to(tablet-small) {
    flex-direction: column;

    &::-webkit-scrollbar-track {
      margin-top: 128px;
    }
  }
}

.overview__column {
  flex: 1 0;

  &:last-child {
    padding-bottom: 86px;
  }

  @include respond-to(tablet) {
    padding-top: 64px;

    &:last-child {
      padding-bottom: 0;
    }

    &::-webkit-scrollbar-track {
      margin-top: 128px;
    }
  }

  @include respond-to(tablet-small) {
    padding-top: 0;

    &.settings {
      padding-top: 64px;
    }

    &::-webkit-scrollbar-track {
      margin-top: initial;
    }
  }

  @include v-scroll;
}

.overview__add-element-button {
  @include bottom-panel__button;

  @include respond-to(tablet) {
    &.button {
      padding: 8px 16px 8px 8px;
    }
  }
}
