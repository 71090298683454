@import '../../../styles/shared';
@import '../../../styles/scroll';
@import '../../../styles/variables';
@import '../../../styles/mixin';

.select-additional {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-gray-lighter;
  display: flex;
  flex-direction: column;
}

.select-additional__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  background-color: $color-white;
  position: relative;
  z-index: $z-index--sidebar-overlay;
  box-shadow: $shadow-panel;
  color: $color-gray-dark;
}

.select-additional__container {
  overflow-y: auto;

  display: grid;
  grid-template: "c" auto;
  grid-gap: 8px;
  padding: 16px;

  & > div:last-child {
    margin-bottom: 86px;
  }

  @include respond-to(tablet) {
    grid-template:
            "c c" auto
            / 1fr 1fr;

    & > div:last-child {
      margin-bottom: 16px;
    }
  }

  @include respond-to(tablet-small) {
    grid-template: "c" auto;
  }

  @extend %scrollbar;
}

.select-additional__item {
  height: 40px;
}

.select-additional__panel--bottom {
  @include bottom-panel;

  @include respond-to(tablet) {
    display: none;
  }
}

.select-additional__add-button {
  @include bottom-panel__button;
}
