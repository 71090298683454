@import '../../../../styles/variables';

.locale-selector {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-s;
  width: 100%;

  &__pill-list {
    flex-grow: 1;

  }

  &__pill {
    width: 40px;
    height: 40px;
  }
}
