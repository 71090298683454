@import './../../styles/variables';

.tooltip {
  background-color: rgba($color-additional, 0.8);
  color: $color-white;
  padding: 16px;
  border-radius: 12px;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  backdrop-filter: blur(30px);
}
