@import './../../styles/_variables';
@import './../../styles/shared';

.material-item {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background-color: $color-white;
  color: $color-gray-dark;
  border: 2px solid transparent;
  box-shadow: 0 4px 8px $color-black--08;

  cursor: pointer;
  position: relative;
  transition-property: border-color, border-width;
  transition-delay: 0.3s;

  &:focus {
    outline: 1px solid $color-focus;
  }

  &__selected {
    border-width: 2px;
    border-color: $color-primary;
  }
}

.material-item__image-wrapper {
  grid-area: img;
  width: 124px;
  height: 124px;
  flex-shrink: 0;
  display: flex;
  margin-right: 16px;
}

.material-item__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.material-item__heading {
  font-weight: bold;
  font-size: $font-size-m;
  line-height: 21px;
}
