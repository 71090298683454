@import './../../styles/_variables';

.elements-card-in-details {
  padding: 16px 16px 0;
  background-color: $color-white;
}

.elements-card-in-details__wrapper {
  margin: 16px 8px 24px;
}

.elements-card-in-details__wrapper--buttons {
  display: flex;
  justify-content: space-between;
}
