@import "./styles/shared";

body, html, #root {
  height: 100%;
}

.layout {
  display: flex;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  overflow: auto;

  scroll-behavior: smooth;
  @extend %scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mirror-x {
  transform: scaleX(-1);
}
