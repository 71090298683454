@import './../../styles/_variables';

.notification__wrapper {
  z-index: $z-index--sidebar-overlay;
}

.notification__wrapper, .notification__mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification__mask {
  opacity: 0.3;
  background: $color-gray-lightest;
  pointer-events: auto;
}