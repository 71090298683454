.icon {
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.svg {
  vertical-align: top;
  fill: currentColor;
}

:global(.icon-spin) {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate270 {
  transform: rotate(-90deg);
}
