@import './../../../styles/_variables';
@import './../../../styles/shared';

.switch {
  background-image: linear-gradient(90deg, $color-gradient-top--24 0%, $color-gradient-bottom--24);
  border-radius: 27px;
  backdrop-filter: blur(8px);
  padding: 7px;
  font-size: 0;
  position: relative;

  @include respond-to(tablet) {
    padding: 8px;
  }
}

.switch__circle {
  position: absolute;
  left: 4px;
  top: 4px;
  display: block;
  width: 46px;
  height: 46px;
  box-shadow: 0px 4px 8px $color-black--16;
  border-radius: 50%;
  background-color: $color-white;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  @include respond-to(tablet) {
    width: 32px;
    height: 32px;
  }
}

.switch__input {
  display: none;
}

.switch__label {
  position: relative;
  z-index: $z-index--overlay;
  display: inline-block;
  cursor: pointer;

  &:nth-child(2) {
    margin-right: 14px;
  }

  @include respond-to(tablet) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.switch__label { //svg
  fill: $color-white;
  transition-property: fill;
  transition-duration: 0.3s;
}

.switch__input:checked + .switch__label {
  fill: $color-gray-dark;
}

.switch__input:nth-child(1):checked + .switch__label ~ .switch__circle {
  transform: translate(0, 0);
}

.switch__input:nth-child(3):checked + .switch__label ~ .switch__circle {
  transform: translate(calc(118%), 0);
}
