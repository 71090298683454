@import '../../styles/variables';
@import '../../styles/shared';

.bottom-sheet {
  background: $color-white;
  border-radius: 16px 16px 0 0;
  height: calc(100% + 12px);
  padding: 4px 16px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 16px $color-black--16;
  cursor: pointer;
  z-index: $z-index--overlay;
  will-change: transform, border-radius, min-width;
  min-width: 0;
  --progress: 0;

  &:focus {
    outline: none;
    transform: translate(-50%, calc(100% - 90px));
  }

  &--expanded, &--idle {
    transition: all 300ms $easing--standard;
  }

  &--expanded {
    border-radius: 0;
    transform: translate(-50%, 0px) scaleX(1.11)!important;
    max-width: 100%;
    min-width: 100%;

    & > * {
      transform: scaleX(0.89);
      transition: transform 300ms $easing--standard;
    }

    .bottom-sheet__handle {
      opacity: 0;
    }
  }
}

.bottom-sheet__handle {
  height: 24px;
  width: 32px;
  position: relative;
  padding-top: 8px;

  &:before, &:after {
    background-color: $color-gray-light;
    width: calc(50% + 2px);
    height: 4px;
    border-radius: 4px;
    content: '';
    display: block;
    position: absolute;
  }

  &:before {
    transform: rotate(calc(calc(1 - var(--progress)) * -15deg));
  }

  &:after {
    transform: rotate(calc(calc(1 - var(--progress)) * 15deg));
    right: 0;
  }
}

.bottom-sheet__content {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
