@import '../../../styles/shared';
@import '../../../styles/scroll';
@import '../../../styles/variables';
@import '../../../styles/mixin';

// ensure higher specificity
.sidebar .element-view {
  pointer-events: none;
}

.element-view {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > * {
    pointer-events: auto;
  }
}

.element-view__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  position: relative;
  z-index: $z-index--sidebar-overlay;
  background-color: $color-white;
  color: $color-gray-dark;
  box-shadow: $shadow-panel;
}

.element-view__button--close {
  visibility: hidden;

  @include respond-to(tablet) {
    visibility: visible;
  }
}

.element-view__wrapper {
  flex: 1;
  overflow: hidden;
  background-color: $color-gray-lighter;
}

.element-view__container {
  display: flex;
  flex-direction: column;
  height: auto;

  @include v-scroll;

  @include respond-to(tablet) {
    flex-direction: row;
    height: 100%;
    padding-bottom: 0;
  }

  @include respond-to(tablet-small) {
    flex-direction: column;
    height: auto;
  }
}

.element-view__column {
  flex: 1 0;
  @include v-scroll;

  &:last-child {
    padding-bottom: 86px;

    @include respond-to(tablet-small) {
      padding-bottom: 0;
    }
  }
}


.element-view__heading {
  @include heading;

  margin: 16px 16px 40px 24px;
  font-size: 24px;
  font-weight: 300;
}

.element-view__handle-container {
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 100%;

  > * {
    pointer-events: auto;
  }
}

.element-view__actions-container {
  position: absolute;
  top: 16px;
  left: 16px;
}

.element-view__action {
  box-shadow: 0 4px 8px $color-black--16;

  & + & {
    margin-left: 16px;
  }

  .icon, svg {
    width: 40px;
    height: 40px;
  }
}

.element-view__handle {
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: calc(100% - 32px);
  width: 340px;
  opacity: 1;
  display: none;
  transform: translate(-50%, calc(100% - 70px));

  @include respond-to(tablet) {
    display: flex;
    animation: bottom-sheet-appear 300ms cubic-bezier(0.0, 0.0, 0.2, 1);
  }
}

@keyframes bottom-sheet-appear {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, calc(100% - 70px));
    opacity: 1;
  }
}

.element-view__details {
  background-color: $color-white;
}

.element-view__panel--bottom {
  @include bottom-panel;

  @include respond-to(tablet) {
    display: none;
  }
}

.element-view__add-button {
  @include bottom-panel__button;
}

.element-view__texture-list {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  padding: 8px;
}
