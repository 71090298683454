@import '../../styles/variables';
@import '../../styles/scroll';

.sidebar {
  box-shadow: 0 0 16px $color-black--16;
  pointer-events: none;
  @include v-scroll;

  > * {
    pointer-events: auto;
  }
}
