@import './../../styles/_variables';

.notification-dialog {
  width: 280px;
  z-index: $z-index--dialog;
  pointer-events: auto;

  &--error {
    background: $color-primary 0 0 no-repeat padding-box;
  }

  b {
    font-weight: bold;
  }
}
